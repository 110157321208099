if (module.hot) {
  module.hot.accept();
};

( function( $ ) {

  $('.slideshow-popup').slick({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    adaptiveHeight: true,
  });

  $('.slideshow-gshock').slick({
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    appendArrows: '.arrows-slider',
    adaptiveHeight: true
  });

  $('.slideshow-gshock-family').slick({
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    appendArrows: '.arrows-slider-family',
    adaptiveHeight: true,
    autoplay: true,
  });
  $('.slideshow-gshock-family-2').slick({
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    appendArrows: '.arrows-slider-family-2',
    adaptiveHeight: true,
    autoplay: true,
  });
  $('.slideshow-gshock-family-3').slick({
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    appendArrows: '.arrows-slider-family-3',
    adaptiveHeight: true,
    autoplay: true,
  });
  $('.slideshow-gshock-family-4').slick({
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    appendArrows: '.arrows-slider-family-4',
    adaptiveHeight: true,
    autoplay: true,
  });

  $('.slidshow-product-1').slick({
    dots: false,
    arrows: true,
    infinite: true,
    cssEase: 'linear',
    centerMode: true,
    centerPadding: "15%",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
    //appendArrows: '.arrows-slider',
  });
  $('.slidshow-product-2').slick({
    dots: false,
    arrows: true,
    infinite: true,
    cssEase: 'linear',
    centerMode: true,
    centerPadding: "15%",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
    //appendArrows: '.arrows-slider',
  });
  $('.slidshow-product-3').slick({
    dots: false,
    arrows: true,
    infinite: true,
    cssEase: 'linear',
    centerMode: true,
    centerPadding: "15%",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
    //appendArrows: '.arrows-slider',
  });
  $('.slidshow-product-4').slick({
    dots: false,
    arrows: true,
    infinite: true,
    cssEase: 'linear',
    centerMode: true,
    centerPadding: "15%",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
    //appendArrows: '.arrows-slider',
  });
  $('.slideshow-episodes').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    adaptiveHeight: true,
    swipeToSlide: false,
    touchMove: false,
    swipe: false
  });

  $('.episodes li').on('click', function(e){
    var dataSlide = $(this).data('slide');
    $('.slideshow-episodes').slick('slickGoTo', dataSlide);
    $('.episodes li').removeClass('active');
    $(this).addClass('active');
  });
  
  $('.smoothScroll').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - 40
        }, 1000); // The number here represents the speed of the scroll in milliseconds
        return false;
      }
    }
  });

  $('#burger').on('click', function(e){
    $(this).toggleClass('active');
    $('.menurs-mobile').toggleClass('active');
  });

} )( jQuery );